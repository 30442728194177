<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],

      ismobile: false,
      activeNames: [],
      activeapp: [],
      loading: true,
      hdinfo: [],
      subid: "",
      switcharray: [],
      reset: true,
    };
  },
  created() {
    var that = this;
    that.title = that.$t("device.button.openstage");
    that.items = [
      {
        text: that.$t("menuitems.devices.text"),
        href: "/",
      },
      {
        text: that.$t("menuitems.devices.lists"),
        href: "/device/list",
      },
      {
        text: that.$t("menuitems.devices.devicedetail"),
        href: "/device/devicedetail?id=" + this.$route.query.id,
      },
      {
        text: that.$t("device.button.openstage"),
        active: true,
      },
    ];

    that.userinfo = JSON.parse(localStorage.getItem("user"));
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      // that.ismobile = true;
    }
    that.gethdinfo();
  },
  methods: {
    gethdinfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "gethdinfo",
            id: that.$route.query.id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.hdinfo = response.data.data.hd;
          for (let i = 0; i < that.hdinfo.length; i++) {
            that.switcharray.push(true);
          }
          that.subid = response.data.data.subcode;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    openbeer() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "openbar",
            id: that.$route.query.id,
            route: that.switcharray,
            reset: that.reset,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          } else if (response.data.status == 404) {
            that.$message.error(that.$t("device.lists.offline"));
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>{{ $t("device.hd.no") }}</th>
                  <th>{{ $t("device.hd.action") }}</th>
                </tr>
              </thead>
              <tbody v-if="hdinfo">
                <tr v-for="(val, idx) in hdinfo" :key="idx">
                  <td>{{ val.no }}</td>
                  <td>
                    <el-switch
                      v-model="switcharray[idx]"
                      :active-text="$t('device.detail.bar.open')"
                      :inactive-text="$t('device.detail.bar.close')"
                    >
                    </el-switch>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("device.detail.bar.reset") }}</td>
                  <td>
                    <el-switch
                      v-model="reset"
                      :active-text="$t('device.detail.bar.yes')"
                      :inactive-text="$t('device.detail.bar.no')"
                    >
                    </el-switch>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="text-center">
                    <el-button type="primary" @click="openbeer" round>{{
                      $t("device.detail.bar.ok")
                    }}</el-button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="2">
                    <el-result
                      icon="info"
                      :title="$t('global.waring')"
                      :subTitle="$t('device.hd.nodata')"
                    >
                      <template slot="extra">
                        <router-link
                          :to="{
                            path: '/device/hd',
                            query: { id: $route.query.id },
                          }"
                        >
                          <el-button type="primary" size="medium">{{
                            $t("device.hd.initialize")
                          }}</el-button>
                        </router-link>
                      </template>
                    </el-result>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>